<template>
    <div class="col-md-12 col-lg-6 col-xl-6 mb-2" style="height:410px">
        <b-overlay :show="isOverlay" rounded="sm" spinner-variant="primary" style="height: 100%;">
            <div v-if="!ShowPuntuacion" class="superpuesto">

            </div>
            <div class="card card-grafica" style="height:100%">
                <div class="card-body">
                    <div class="filtro-grafic" id="grafica_001" v-if="isVisible">
                        <div class="row justify-content-start">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div class="form-inline mb-2">
                                    <label class="title-grafic side-l mr-3">Calidad del Servicio</label>
                                    <v-date-picker
										mode="range"
										v-model="rangeDate"
										@input="GetDatosDashboard('3')"
										:input-props="{
											class: 'form-control form-control-sm mr-2 calendar',
											placeholder:
												'Selecciona un rango de fecha para buscar',
											readonly: true
										}"
									/>
                                    <button type="button" class="btn close abs_01" @click="Ocultar()">
										<i class="fal fa-times"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-10 col-sm-10 col-md-8 col-lg-8">
                            <h6 class="title-grafic side-l">Calidad del Servicio</h6>
                        </div>
                        <div class="col-2 col-sm-2 col-md-4 col-lg-4 text-right">
                            <button type="button" class="btn-fil-002" title="Filtros" @click="MostrarFiltro()">
                                <i class="fas fa-filter"></i>
                            </button>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                            <hr />
                        </div>
                    </div>
                    <div class="row mb-4 mt-1" v-for="(item,index) in Calificaciones" :key="index">
                        <div class="col-lg-3 d-flex align-items-center">
                            <span class="bold h1 text-info mt-1">{{ item.PromedioEstrellas }} / </span> <span class="mt-1 bold h1 ml-1 mr-2">{{ 3 }}</span> <star-rating :inline="true" :rating="1.0" :show-rating="false" :read-only="true" :increment="0.5" :max-rating="1" :star-size="50"></star-rating>
                        </div>
                        <div class="col-lg-3">
                            <treeselect class="mt-2" style="top: -4px;" :maxHeight="180" :options="Clientes" placeholder="Cliente..." v-model="item.IdCliente" @input="CambiaCliente(index);GetDatosDashboard('2',index);"/>
                        </div>
                        <div class="col-lg-3">
                            <select @change="CambioTipoFiltro(index)" v-model="item.TipoFiltro" class="form-control mt-1" name="" id="">
                                <option value="">Seleccione Una Opción de Filtro</option>
                                <option v-show="item.IdCliente!=null" value="1">Propiedad</option>
                                <option value="2">Tipo de Servicio</option>
                                <option v-show="item.IdCliente!=null" value="3">Contrato</option>
                                <option value="4">Técnico</option>
                            </select>
                        </div>
                        <div class="col-lg-3">
                            <treeselect :disabled="true" v-if="item.TipoFiltro==''" class="mt-2" style="top: -4px;" :maxHeight="180" placeholder="Seleccione un Filtro..." :options="[]"/>
                            <treeselect v-if="item.TipoFiltro=='1'" class="mt-2" style="top: -4px;" :maxHeight="180" :options="Sucursales" placeholder="Propiedad..." v-model="item.IdClienteS" @input="GetDatosDashboard('2',index);"/>
                            <treeselect v-if="item.TipoFiltro=='2'" class="mt-2" style="top: -4px;" :maxHeight="180" :options="TiposServicio" placeholder="Tipo de Servicio..." v-model="item.IdTipoSer" @input="GetDatosDashboard('2',index);"/>
                            <treeselect v-if="item.TipoFiltro=='3'" class="mt-2" style="top: -4px;" :maxHeight="180" :options="Contratos" placeholder="Contrato..." v-model="item.IdContrato" @input="GetDatosDashboard('2',index);"/>
                            <treeselect v-if="item.TipoFiltro=='4'" class="mt-2" style="top: -4px;" :maxHeight="180" :options="Tecnicos" placeholder="Técnico..." v-model="item.Personal" @input="GetDatosDashboard('2',index);"/>
                        </div>
                    </div>
                    <!-- <div class="row">
                        <div class="col-lg-12 d-flex justify-content-center">
                            <star-rating :rating="parseFloat(PromedioEstrellas)" :show-rating="false" :read-only="true" :increment="0.5" :max-rating="3" :star-size="180"></star-rating>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <h2 class="text-center" style="font-size:80px;">{{ PromedioReal }}</h2>
                        </div>
                    </div> -->
                </div>
            </div>
        </b-overlay>
    </div>
</template>

<script>
import StarRating from 'vue-star-rating';
export default {
    components:{
        StarRating
    },
    props:['pShowPuntuacion'],
    data() {
        return {
            isVisible:false,
            isOverlay: true,
            IdCliente:null,
            IdClienteS:null,
            IdTrabajador:null,
            rangeDate:{
                start:null,
                end:null
            },
            Clientes:[],
            Sucursales:[],
            Tecnicos:[],
            TiposServicio:[],
            Contratos:[],
            Calificaciones:[
                {
                    Promedio:0,
                    PromedioEstrellas:0,
                    IdCliente:null,
                    IdClienteS:null,
                    IdTipoSer:null,
                    IdConfigS:null,
                    IdContrato:null,
                    IdTrabajador:null,
                    TipoFiltro:''
                },
                {
                    Promedio:0,
                    PromedioEstrellas:0,
                    IdCliente:null,
                    IdClienteS:null,
                    IdTipoSer:null,
                    IdConfigS:null,
                    IdContrato:null,
                    IdTrabajador:null,
                    TipoFiltro:''
                },
                {
                    Promedio:0,
                    PromedioEstrellas:0,
                    IdCliente:null,
                    IdClienteS:null,
                    IdTipoSer:null,
                    IdConfigS:null,
                    IdContrato:null,
                    IdTrabajador:null,
                    TipoFiltro:''
                },
                {
                    Promedio:0,
                    PromedioEstrellas:0,
                    IdCliente:null,
                    IdClienteS:null,
                    IdTipoSer:null,
                    IdConfigS:null,
                    IdContrato:null,
                    IdTrabajador:null,
                    TipoFiltro:''
                }
            ],
            IdConfigS:null,
            IdTipoSer:null,
            PromedioReal:0,
            PromedioEstrellas:0,
            ShowPuntuacion:false
        }
    },
    methods: {
        async GetDatosDashboard(Camino,Index=null){
            let Filtros={};
            Filtros.Camino=Camino;
            if (this.rangeDate.start !=null && this.rangeDate.end !=null) {
                Filtros.Fecha_I=this.rangeDate.start;
                Filtros.Fecha_F=this.rangeDate.end;
            }
            if (Camino=='2') {
                Filtros.IdCliente=this.Calificaciones[Index].IdCliente;
                Filtros.IdClienteS=this.Calificaciones[Index].IdClienteS;
                Filtros.Personal=this.Calificaciones[Index].IdTrabajador;
                Filtros.IdConfigS=this.Calificaciones[Index].IdConfigS;
                Filtros.IdTipoSer=this.Calificaciones[Index].IdTipoSer;
                Filtros.IdContrato=this.Calificaciones[Index].IdContrato;
            }
            if (Camino=='3') {
                Filtros.Calificaciones=JSON.stringify(this.Calificaciones);
            }
            this.isOverlay=true;
            await this.$http.get('despachoonepuntuacion/get',{
                params:Filtros
            }).then((res)=>{
                switch (Camino) {
                    case '1':
                        this.Calificaciones=res.data.data.Calificaciones;
                        break;
                    case '2':
                        this.Calificaciones[Index].Promedio=res.data.data.PromedioEstrellas;
                        this.Calificaciones[Index].PromedioEstrellas=res.data.data.PromedioEstrellas;
                        break;
                    case '3':
                        this.Calificaciones=res.data.data.Calificaciones;
                        break;
                }
                this.PromedioReal=res.data.data.Promedio;
                this.PromedioEstrellas=res.data.data.PromedioEstrellas;
            }).finally(()=>{
                this.isOverlay=false;
            })
        },
        CambiaCliente(Index){
            this.Calificaciones[Index].IdClienteS=null;
            this.Calificaciones[Index].IdContrato=null;
        },
        CambioTipoFiltro(Index){
            this.Calificaciones[Index].IdClienteS=null;
            this.Calificaciones[Index].IdTipoSer=null;
            this.Calificaciones[Index].IdContrato=null;
            this.Calificaciones[Index].Personal=null;
            this.Sucursales=[];
            this.Tecnicos=[];
            this.TiposServicio=[];
            this.Contratos=[];
            switch (this.Calificaciones[Index].TipoFiltro) {
                case '1':
                    this.GetListaClienteS(Index);
                    break;
                case '2':
                    this.ListaTipoServicio(Index);
                    break;
                case '3':
                    this.ListaNumContrato(Index);
                    break;
                case '4':
                    this.ListaTecnicos(Index);
                    break;
            }
        },
        ListaClientes(){
            this.$http.get("clientes/get"
			).then((res)=>{
				this.Clientes=res.data.data.clientes.map(function(obj){
					return {id:obj.IdCliente,label:obj.Nombre}
				})
			});
        },
        GetListaClienteS(Index){
			this.$http.get("clientesucursal/get",{
				params:{
					Todos:true,
					RegEstatus:'A',
					IdCliente:this.Calificaciones[Index].IdCliente
				}
			}
			).then((res)=>{
				this.Sucursales=res.data.data.sucursal.map(function(obj){
					return {id:obj.IdClienteS,label:obj.Nombre}
				})
			});
        },
        ListaNumContrato(Index){
            this.IdContrato='';
			this.$http.get(
				'numcontratocalif/get',
				{
					params:{IdCliente:this.Calificaciones[Index].IdCliente}
				}
			).then( (res) => {
				this.Contratos =res.data.data.row.map(function(obj){
                    return {id:obj.IdContrato,label:obj.NumeroC}
                });
			});
        },
        ListaTecnicos(Index){
            this.$http
				.get("trabajador/get", {
					params: {
						Rol: "USUARIO APP",
						IdPerfil: 4
					}
				})
				.then(res => {
					this.Tecnicos = res.data.data.trabajador.map(function(obj){
                        return {id:obj.IdTrabajador,label:obj.Nombre}
                    });
				});
        },
        ListaTipoServicio(Index){
            this.$http.get(
                    'tiposervicio/get',
                    {
                        params:{Nombre:'',Entrada:50,pag:0, RegEstatus:'A'}
                    }
                ).then( (res) => {
                    this.TiposServicio =res.data.data.tiposervicio.map(function(obj){
                        return {id:obj.IdTipoSer,label:obj.Concepto}
                    });
                });
        },
        MostrarFiltro(){
            this.isVisible = true;
        },
        Ocultar() {
			this.isVisible = false;
		},
        PaquetesSucursal() {
			this.$http
				.get("paquetexsucursalsinperfil/get", {
					params: { IdSucursal: JSON.parse(sessionStorage.getItem("user")).IdSucursal }
				})
				.then(res => {
					// this.ListaPaquetes = res.data.data.paquetexsucursal;
                    let Paquete = res.data.data.paquetexsucursal.find(
						item => item.IdPaquete == 30
					);
                    if (Paquete!=undefined) {
                        this.ShowPuntuacion=Paquete.Estatus;
                    }
                    if (this.ShowPuntuacion) {
                        this.GetDatosDashboard('1');
                    }
                    else{
                        this.isOverlay=false;
                    }
				});
		},
    },
    created() {
        var date = new Date(),
		y = date.getFullYear(),
		m = date.getMonth();
		var firstDay = new Date(y, m, 1);
		var lastDay = new Date(y, m + 1, 0);
		this.rangeDate = {
			start: firstDay,
			end: lastDay
		};
    },
    mounted() {
        this.ListaClientes();
        this.PaquetesSucursal();
        // this.GetDatosDashboard('1');
    },
}
</script>

<style>
    .superpuesto{
        z-index: 2;
        position: absolute;
        background: rgba(217, 217, 217, 0.4);
        width: 100%;
        height: 100%;
    }
</style>