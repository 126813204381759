<template>
    <div>
        <div class="form-group row">
            <div class="col-lg-12">
                <label for="">Cantidad</label>
                <input type="number" class="form-control" v-model="PaqueteUsuarioMonitoreo.Cantidad">
                <label id="lblmsuser" style="color:red"><Cvalidation v-if="errorvalidacion.Cantidad" :Mensaje="errorvalidacion.Cantidad[0]"></Cvalidation></label>
            </div>
        </div>
    </div>
</template>

<script>
import Cbtnsave from '@/components/Cbtnsave.vue'
import Cvalidation from '@/components/Cvalidation.vue'
export default {
    name:'Form',
    props:['poBtnSave'],
    data() {
        return {
            Modal:true,
            Name:'UsuariosMonitoreoForm',
            PaqueteUsuarioMonitoreo:{
                IdPaqueteUsuarioMonitoreo:0,
                Cantidad:0
            },
            errorvalidacion:[]
        }
    },
    methods: {
        async Guardar(){
            this.poBtnSave.disableBtn=true;
            let formData=new FormData();
            formData.set('IdPaqueteUsuarioMonitoreo',this.PaqueteUsuarioMonitoreo.IdPaqueteUsuarioMonitoreo);
            formData.set('Cantidad',this.PaqueteUsuarioMonitoreo.Cantidad);
            await this.$http.post('paqueteusuariomonitoreo/post',formData
            ).then((res)=>{
                this.poBtnSave.disableBtn=false;  
                this.poBtnSave.toast=1;
                $('#ModalForm').modal('hide');
                this.bus.$emit('List');
            }).catch((err)=>{
                this.errorvalidacion=err.response.data.message.errores;
                this.poBtnSave.disableBtn=false;
                this.poBtnSave.toast=2;
            })
        },
        Limpiar(){
            this.PaqueteUsuarioMonitoreo={
                IdPaqueteUsuarioMonitoreo:0,
                Cantidad:0
            },
            this.errorvalidacion=[];
        },
        get_one(){
            this.$http.get('paqueteusuariomonitoreo/recovery',{
                params:{
                    IdPaqueteUsuarioMonitoreo:this.PaqueteUsuarioMonitoreo.IdPaqueteUsuarioMonitoreo
                }
            }).then((res)=>{
                this.PaqueteUsuarioMonitoreo.IdPaqueteUsuarioMonitoreo=res.data.data.PaqueteUsuarioMonitoreo.IdPaqueteUsuarioMonitoreo;
                this.PaqueteUsuarioMonitoreo.Cantidad=res.data.data.PaqueteUsuarioMonitoreo.Cantidad;
            })
        }
    },
    created() {
        this.bus.$off('Nuevo');
        this.bus.$on('Nuevo',(data,Id)=> 
        {
            this.poBtnSave.toast=0;
            this.poBtnSave.disableBtn=false; 
            this.bus.$off('Save');

            this.bus.$on('Save',()=>
            {
                this.Guardar();
            });

            this.Limpiar();
            if (Id>0) 
            {
                this.PaqueteUsuarioMonitoreo.IdPaqueteUsuarioMonitoreo=Id;
                this.get_one();
            }
            // else{
            //     this.get_one();
            // }
        });
        if (this.Id!=undefined)
        {
            this.PaqueteUsuarioMonitoreo.IdPaqueteUsuarioMonitoreo=this.Id;
            this.get_one();
        }
    }
}
</script>

<style>

</style>