<template>
    <div class="form-row">

        <div v-if="RolUsuario!='Monitoreo'" class="col-lg-12">
            <label for="">Observaciones *</label>
            <textarea class="form-control" name="" id="" cols="30" rows="10" v-model="observacion.Observacion"></textarea>
            <Cvalidation v-if="this.errorvalidacion.Observacion" :Mensaje="errorvalidacion.Observacion[0]"></Cvalidation>
        </div>

        <div v-else class="col-lg-12">
            <label for="">Observaciones *</label>
            <textarea disabled class="form-control" name="" id="" cols="30" rows="10" v-model="observacion.Observacion"></textarea>
            <Cvalidation v-if="this.errorvalidacion.Observacion" :Mensaje="errorvalidacion.Observacion[0]"></Cvalidation>
        </div>

    </div>
</template>
<script>

import Cbtnsave from '@/components/Cbtnsave.vue';
import Cvalidation from '@/components/Cvalidation.vue'

export default{
    props:["oEquipo","poBtnSave","RolUsuario"],
    data(){
        return{
            Modal:true,
            FormName:'observaciones',
            observacion:{
                IdObservacion:0,
                IdEquipo:0,
                Observacion:''
            },
            errorvalidacion:[]
        }
    },
    components:{Cbtnsave, Cvalidation},
    methods:{
        async Guardar(){
            // this.poBtnSave.toast=0;
            this.poBtnSave.disableBtn=false;
            let formData=new FormData();
            formData.set('IdObservacion',this.observacion.IdObservacion);
            formData.set('Observacion',this.observacion.Observacion);
            formData.set('IdEquipo',this.oEquipo);
            await this.$http.post('observacionequipo/post',formData,{
                
            }).then((res)=>{
                this.poBtnSave.disableBtn=false;
                // this.poBtnSave.toast=1;
                this.$toast.success("Información guardada");
                $('#observaciones').modal('hide');
                this.bus.$emit('Listar');
            }).catch((err)=>{
                this.errorvalidacion=err.response.data.message.errores;
                this.poBtnSave.disableBtn=false;
                // this.poBtnSave.toast=2;
                this.$toast.info("Complete los campos por favor");
            })
        },
        getOne(){
            this.$http.get('observacionequipo/recovery',
            {
                params:{IdObservacion:this.observacion.IdObservacion}
            }).then((res)=>{
                this.observacion.IdObservacion=res.data.data.observacion.IdObservacion;
                this.observacion.IdEquipo=res.data.data.observacion.IdEquipo;
                this.observacion.Observacion=res.data.data.observacion.Observacion;
            });
        },
        Limpiar(){
            this.observacion.IdObservacion=0;
            this.observacion.IdEquipo=0;
            this.observacion.Observacion='';
        }
    },
    created(){
        this.poBtnSave.toast=0;
        // this.poBtnSave.disableBtn=false;
        // this.bus.$off('Save');
        // this.bus.$on('Save',()=>{
        //     this.Guardar();
        // });
        // if(this.oEquipo.IdObservacion>0){
        //         this.getOne();
        //         this.bus.$emit('Desbloqueo',false);
        //     }
        // if (this.oEquipo.IdObservacion!=undefined) {
        //     this.getOne();
        // }
        this.bus.$off('NuevaObservacion');
        this.bus.$on('NuevaObservacion',(data,Id)=>{
            this.poBtnSave.disableBtn=false;
            this.bus.$off('Save_Observacion');
            this.bus.$on('Save_Observacion',()=>{
                this.Guardar();
            });
            this.Limpiar();
            if(Id>0){
                this.observacion.IdObservacion=Id;
                this.getOne();
                this.bus.$emit('Desbloqueo',false);
            }
        });
        if (this.observacion.IdObservacion!=0) {
            this.getOne();
        }
    }
}
</script>