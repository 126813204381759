<template>
  <div>
    <CHeader :pHeaderProps=HeaderProps>
        <template slot="Contenido">

            <CLoader :pConfigLoad="ConfigLoad">
				<template slot="BodyFormLoad">
                    <div class="row mt-3" v-if="Origen=='Agenda'">
                        <div class="col-3 col-sm-3">
                            <h2>Agenda</h2>
                        </div>
                        <div class="col-9 col-sm-9">
                            <h2 class="text-right">{{ FechaAgenda() }}</h2>
                        </div>
                    </div>
                    <div  v-show="ListServicio.length > 0"  class="row mt-3" v-for="(item,index) in ListServicio" :key="index" @click="IrADetalleServicio(item.IdServicio)">
                        <div class="col-12 col-sm-12">
                            <div :class="item.EstadoS=='PENDIENTE' ? 'card card-01 shadow card-servicio-activo' : 'card card-01 shadow'">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-3 col-sm-3 position-relative">
                                            <div class="icon-circul d-flex justify-content-center align-content-center" :style="'background-color:'+item.FondoColor+';'">
                                                <img class="icon-servicio" :src=RutaIconos+item.Imagen2 alt="nada">
                                            </div>
                                        </div>
                                        <div class="col-9 col-sm-9">
                                            <p v-show="item.Offline==1" class="text-right"><b>Offline</b></p>
                                            <p><b>Cliente:</b> {{ item.Cliente }}</p>
                                            <p><b>Propiedad:</b> {{ item.Propiedad }}</p>
                                            <p><b>Hora:</b> {{ getHourClean(item.HoraInicio) }}</p>
                                            <p><b>Responsable:</b> {{ item.Responsable }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-show="ListServicio.length == 0"  class="row mt-3">
                        <div class="col-12 col-sm-12 text-center mt-3">
                            <h2>Sin Servicios.</h2>
                        </div>
                    </div>
                </template>
            </CLoader>
            <div class="row mt-3 mb-3" v-if="ListServicio.length>=3">
                <div class="col-lg-12 d-flex justify-content-center">
                    <button v-if="ListServicio.length>=3" @click="Lista" type="button" class="btn btn-01 icon-circul-03" :disabled="btnRecargar.blockear">
                        <i v-show="!btnRecargar.blockear" class="far fa-redo-alt fa-3x"></i>
                        <i v-show="btnRecargar.blockear" class="fa fa-spinner fa-pulse fa-1x fa-fw"></i> 
                    </button>
                </div>
            </div>
            <button v-if="ListServicio.length<3" @click="Lista" type="button" class="btn btn-01 icon-circul-02" :disabled="btnRecargar.blockear">
                <i v-show="!btnRecargar.blockear" class="far fa-redo-alt fa-3x"></i>
                <i v-show="btnRecargar.blockear" class="fa fa-spinner fa-pulse fa-1x fa-fw"></i> 
            </button>
        </template>
    </CHeader>
  </div>
</template>

<script>
import CHeader from "@/views/webapp/components/HeaderWebApp.vue";
import moment from 'moment';
import CLoaderVue from '../../../components/CLoader.vue';
export default {
    props:['pOrigen','pFecha'],
    components:{
        CHeader,
        CLoaderVue
    },
    data() {
        return {
            HeaderProps:{
                GoToHome:true
            },
            ListServicio:[],
            RutaIconos:'',
            Filtro:{
                Pagina:'',
                Entrada:'',
                Fecha_I:'',
                Tipo:'',
                IdTrabajador:''
            },
            ConfigLoad:{
				ShowLoader:true,
				ClassLoad:true,
			},
            btnRecargar: {
                blockear: true,
                mod1 : 'btn btn-01 icon-circul-02',
                mod2 : 'btn btn-01-amarillo icon-circul-02'
            },
            Online:true,
            Origen:''
        }
    },
    methods: {
        Lista(){
            this.ConfigLoad.ShowLoader = true;
            this.btnRecargar.blockear = true;
            if (this.Online) {
                this.$http.get('webapp/servicios',{
                    params:{
                        pag:this.Filtro.Pagina,
                        Entrada:this.Filtro.Entrada,
                        FechaI:this.Filtro.Fecha_I,
                        Tipo:this.Filtro.Tipo,
                        IdTrabajador:this.Filtro.IdTrabajador,
                    }
                }).then((res)=>{
                    this.RutaIconos=res.data.ruta;
                    this.ListServicio=res.data.Lista;
                    let ListServicioOffline=this.ListServicio.filter(element => element.Offline=='1');
                    localStorage.setItem("serviciosoffline",JSON.stringify(ListServicioOffline));
                    localStorage.setItem("rutaiconos",this.RutaIconos);
                }).finally(()=>{
			    	this.ConfigLoad.ShowLoader = false;
                    this.btnRecargar.blockear = false;
			    });
            }
            else{
                this.RutaIconos=localStorage.getItem("rutaiconos");
                this.ListServicio=JSON.parse(localStorage.getItem("serviciosoffline"));
                this.ConfigLoad.ShowLoader = false;
                this.btnRecargar.blockear = false;
            }
        },
        Regresar(){
            this.$router.push({name:'AgendaWebApp'});
        },
        getHourClean(cadena){
            let cadenasplit=cadena.split(':');
            return cadenasplit[0]+':'+cadenasplit[1];
        },
        IrADetalleServicio(IdServicio){
            if (!this.Online) {
                localStorage.setItem("idservicio",IdServicio);
            }
            this.$router.push({ name: "ServicioDetalleWebApp", params: {pIdServicio:IdServicio} });
        },
        PingServer(){
            this.$http.get('webapp/ping',{
                timeout:2000
            }
            ).then(()=>{
                this.Online=true;
                clearInterval(this.$store.state.timeServicio);
		        this.$store.state.timeServicio = setInterval(() => {this.Lista();}, 180000);
            }).catch((err)=>{
                // console.log(err);
                this.Online=false;
                this.Lista();
            }).finally(()=>{
                this.Lista();
            })
        },
        FechaAgenda(){
            let ArrayFecha=this.Filtro.Fecha_I.split('-');
            let Fecha=new Date(ArrayFecha[0],ArrayFecha[1]-1,ArrayFecha[2]);
            let Opciones = {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
            };
            return Fecha.toLocaleDateString('es-MX',Opciones);
        }
    },
    created() {
        this.bus.$off('EmitReturn');
        this.bus.$on('EmitReturn',()=>{
            this.Regresar();
        });
        if (this.pOrigen!=undefined) {
            sessionStorage.setItem('Origen',this.pOrigen);
        }
        this.Origen=sessionStorage.getItem('Origen');
        if (this.Origen=='Agenda') {
            sessionStorage.setItem('Fecha',this.pFecha);
            this.HeaderProps.ShowReturn=true;
        }
        else{
            sessionStorage.setItem('Fecha',moment().format('YYYY-MM-DD'));
        }
        this.Filtro.Fecha_I=sessionStorage.getItem('Fecha');
        this.Filtro.IdTrabajador=JSON.parse(sessionStorage.getItem('user')).IdTrabajador;
    },
    mounted() {
        this.PingServer();
        // this.Lista();
    },
    destroyed() {
        clearInterval(this.$store.state.timeServicio);
    },
}
</script>

<style>
.icon-servicio {
    width: 58px;
    height: 58px;
    padding: 0px;
    margin: auto;
 
}

</style>