<template>
  <div>
    <input type="hidden" :disabled="Validate">
    <header class="mov_box_01 d-flex justify-content-center align-items-center">
        <img v-show="HeaderProps.ShowLogo" src="../../../images/logo_84x30.png" alt="" class="img-fluid">
        <i v-if="HeaderProps.ShowReturn" class="fas fa-chevron-left fixed-left mt-2 ml-2 fa-2x" @click="Regresar"></i>
        <h6 class="text-center text-white text-header">{{ HeaderProps.TextoHeader }}</h6>
    </header>
    <main v-show="HeaderProps.ShowSubHeader" class="mov_box_02">
        <div class="container-fluid">
            <div class="row" v-if="HeaderProps.TextoCentroSubHeader!=''">
                <div class="col-sm-12">
                    <p class="text-center text-subheader">{{ HeaderProps.TextoCentroSubHeader }}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-9 col-sm-9">
                    <img v-if="HeaderProps.ShowFotoPerfil" :src="Foto" alt="Diego" class="rounded-circle img-fluid float-left mr-2" style="width: 50px;">
                    <div class="mt-2" style="line-height: 1rem;">
                        <h6 class="text-white" v-if="HeaderProps.ShowTecnico">{{ Usuario.Nombre + ' ' + Usuario.Apellido }}</h6>
                        <!-- <br> -->
                        <h6 class="text-white text-subheader" v-if="HeaderProps.IndicadorAccion">{{ HeaderProps.IndicadorAccionTexto }}</h6>
                        <h6 class="text-white" v-if="HeaderProps.ShowFechaHoy">{{ FechaHoy }}</h6>
                    </div>
                </div>
                <div class="col-3 col-sm-3 text-right">
                    <i @click="EjecutarSubAccion" v-if="HeaderProps.ShowIconSubAccion" :class="HeaderProps.IconClassSubAccion"></i>
                    <div v-if="HeaderProps.ShowMenuDropdown" class="dropdown mt-2">
                        <button class="btn btn-01 dropdown-toggle toggle-menu" type="button" data-toggle="dropdown" aria-expanded="false">
                            <i class="fas fa-ellipsis-v fa-fw"></i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item" href="#"><img :src="Foto" alt="" class="avatar"> Mi Perfil</a>
                            <a @click="IrAHome" v-if="HeaderProps.GoToHome" class="dropdown-item" href="#">
                                <div class="avatar bg-gradient-secondary  me-3  my-auto">
                                    <i class="fas fa-home"></i>
                                </div>
                                Inicio
                            </a>
                            <a @click="CerrarSession" class="dropdown-item" href="#">
                            <div class="avatar bg-gradient-secondary  me-3  my-auto">
                                <i class="fas fa-sign-out-alt"></i>
                            </div>
                            Salir</a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div v-if="!HeaderProps.ShowLogo">
                <i class="fas fa-chevron-left fixed-left mt-1 ml-2 fa-2x" @click="Regresar"></i>
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-sm-12"><p class="text-center">{{ HeaderProps.TextoHeader }}</p></div>
                    </div>
                </div>
            </div>
            <div class="row" v-else>
                <div class="col-9 col-sm-9">
                    <img :src="Foto" alt="Diego" class="rounded-circle img-fluid float-left mr-2" style="width: 50px;">
                    <div class="mt-2" style="line-height: 1rem;"><span>{{ Usuario.Nombre + ' ' + Usuario.Apellido }}</span><br><span>{{ FechaHoy }}</span></div>
                </div>
                <div class="col-3 col-sm-3 text-right">
                    <div class="dropdown mt-2">
                        <button class="btn btn-01 dropdown-toggle toggle-menu" type="button" data-toggle="dropdown" aria-expanded="false">
                            <i class="fas fa-ellipsis-v fa-fw"></i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item" href="#"><img :src="Foto" alt="" class="avatar"> Mi Perfil</a>
                            <a @click="IrAHome" v-if="HeaderProps.GoToHome" class="dropdown-item" href="#">
                                <div class="avatar bg-gradient-secondary  me-3  my-auto">
                                    <i class="fas fa-home"></i>
                                </div>
                                Inicio
                            </a>
                            <a class="dropdown-item" href="#">
                            <div class="avatar bg-gradient-secondary  me-3  my-auto">
                                <i class="fas fa-sign-out-alt"></i>
                            </div>
                            Salir</a>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </main>
    <section class="container-fluid">
        <slot name="Contenido"></slot>
    </section>
  </div>
</template>

<script>
import moment from 'moment';
export default {
    name:"HeaderWebApp",
    props:['pHeaderProps'],
    data() {
        return {
            Usuario:{},
            Foto:'',
            FechaHoy:'',
            HeaderProps:{
                ShowLogo:true,
                Origen:'',
                TextoHeader:'',
                ShowFiltros:false,
                GoToHome:false,
                ShowFotoPerfil:true,
                ShowSubHeader:true,
                ShowTecnico:true,
                ShowFechaHoy:true,
                ShowReturn:false,
                ShowMenuDropdown:true,
                ShowIconSubAccion:false,
                IconClassSubAccion:'',
                TextoCentroSubHeader:'',
                IndicadorAccion:false,
                IndicadorAccionTexto:''
            }
        }
    },
    methods: {
        GetDatos(){
            const options = {
                weekday: 'long',
                // year: 'numeric',
                month: 'long',
                day: 'numeric',
            };
            this.FechaHoy=new Date().toLocaleDateString('es-MX',options);
            let Datos=JSON.parse(sessionStorage.getItem("user"));
            let ruta = sessionStorage.getItem("ruta");
            this.Foto = ruta + Datos.Foto2;
            this.Usuario=Datos;
            // this.$router.push({ name: "HomeWebApp", params: {} });
        },
        Regresar(){
            this.bus.$emit('EmitReturn');
        },
        IrAHome(){
            this.$router.push({ name: "HomeWebApp", params: {} });
        },
        EjecutarSubAccion(){
            this.bus.$emit('EjecutarSubAccion');
        },
        CerrarSession() {
			this.$store.dispatch("logout");
			this.$router.push({ name: "Login" });
		},
        AlertaCheckOut(){
            if (this.$store.state.CheckInEstatus=='Checkin') {
                let Mensaje='';
                const Hoy=new Date();
                let FechaSalida=new Date();
                FechaSalida.setHours(Number(this.Usuario.HoraSalida),Number(this.Usuario.MinutoSalida),0,0);
                if (Number(this.Usuario.HoraEntrada)>Number(this.Usuario.HoraSalida)) {
                    FechaSalida.setDate(FechaSalida.getDate()+1);
                }
                const Diferencia=FechaSalida.getTime()-Hoy.getTime();
                if (Diferencia<=900000) {
                    const Tiempo=Math.max(0,Math.floor((FechaSalida.getTime()-Hoy.getTime())/1000));
                    // console.log(Tiempo);
                    const Minutos = Math.floor(Tiempo / 60);
                    const Segundos = Tiempo % 60;
                    Mensaje='Quedan '+Minutos+' Minutos Y '+Segundos+' Segundos Para su Hora de Salida';
                    // return { Minutos, Segundos };
                    this.$toast.warning(Mensaje);
                }
            }
        }
    },
    mounted() {
        this.GetDatos();
        clearInterval(this.$store.state.TiempoRestanteCheckOutTimer);
        // console.log(this.$store.state.CheckInEstatus);
        this.$store.state.TiempoRestanteCheckOutTimer = setInterval(() => {this.AlertaCheckOut();}, 120*1000);
    },
    beforeDestroy() {
		clearInterval(this.$store.state.TiempoRestanteCheckOutTimer);
	},
    computed:{
        Validate(){
            if (this.pHeaderProps.ShowLogo!=undefined) {
                this.HeaderProps.ShowLogo=this.pHeaderProps.ShowLogo;
            }
            if (this.pHeaderProps.Origen!=undefined) {
                this.HeaderProps.Origen=this.pHeaderProps.Origen;
            }
            if (this.pHeaderProps.TextoHeader!=undefined) {
                this.HeaderProps.TextoHeader=this.pHeaderProps.TextoHeader;
            }
            if (this.pHeaderProps.ShowFiltros!=undefined) {
                this.HeaderProps.ShowFiltros=this.pHeaderProps.ShowFiltros;
            }
            if (this.pHeaderProps.GoToHome!=undefined) {
                this.HeaderProps.GoToHome=this.pHeaderProps.GoToHome;
            }
            if (this.pHeaderProps.ShowFotoPerfil!=undefined) {
                this.HeaderProps.ShowFotoPerfil=this.pHeaderProps.ShowFotoPerfil;
            }
            if (this.pHeaderProps.ShowSubHeader!=undefined) {
                this.HeaderProps.ShowSubHeader=this.pHeaderProps.ShowSubHeader;
            }
            if (this.pHeaderProps.ShowTecnico!=undefined) {
                this.HeaderProps.ShowTecnico=this.pHeaderProps.ShowTecnico;
            }
            if (this.pHeaderProps.ShowFechaHoy!=undefined) {
                this.HeaderProps.ShowFechaHoy=this.pHeaderProps.ShowFechaHoy;
            }
            if (this.pHeaderProps.ShowReturn!=undefined) {
                this.HeaderProps.ShowReturn=this.pHeaderProps.ShowReturn;
            }
            if (this.pHeaderProps.ShowMenuDropdown!=undefined) {
                this.HeaderProps.ShowMenuDropdown=this.pHeaderProps.ShowMenuDropdown;
            }
            if (this.pHeaderProps.ShowIconSubAccion!=undefined) {
                this.HeaderProps.ShowIconSubAccion=this.pHeaderProps.ShowIconSubAccion;
            }
            if (this.pHeaderProps.IconClassSubAccion!=undefined) {
                this.HeaderProps.IconClassSubAccion=this.pHeaderProps.IconClassSubAccion;
            }
            if (this.pHeaderProps.TextoCentroSubHeader!=undefined) {
                this.HeaderProps.TextoCentroSubHeader=this.pHeaderProps.TextoCentroSubHeader;
            }
            if (this.pHeaderProps.IndicadorAccion!=undefined) {
                this.HeaderProps.IndicadorAccion=this.pHeaderProps.IndicadorAccion;
            }
            if (this.pHeaderProps.IndicadorAccionTexto!=undefined) {
                this.HeaderProps.IndicadorAccionTexto=this.pHeaderProps.IndicadorAccionTexto;
            }
            return this.HeaderProps.ShowLogo;
        }
    }
}
</script>

<style>

</style>