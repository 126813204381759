<template>

    <div>
        <div class="form-group">
            <!-- <div class="form-check form-check-inline">
                <div class="checkbox">
                    <label>
                        <input
                            type="checkbox"
                            v-model="Checked"
                            name="optionsCheckboxes"
                        /><span class="checkbox-material-green"
                            ><span class="check"></span
                        ></span>
                        Copiar Documentos de Otros Equipos
                    </label>
                </div>
            </div> -->
        </div>
        <div class="row">
            <div class="col-lg-4">
                <label>Id Equipo *</label>
                <treeselect
                    :maxHeight="180"
                    :options="ListaCodigos"
                    placeholder="Busque un código..."
                    v-model="Equipo.ActivoId"
                    @input="Equipo.ActivoId!=0 ? SeleccionarCodigo() : ''"
                />
            </div>

            <div class="col-lg-4">
                <label >Id Activo *</label>
                <input  class="form-control"  v-model="Equipo.Nequipo" placeholder="Id Activo">
                <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.Nequipo" :Mensaje="errorvalidacion.Nequipo[0]"></Cvalidation></label>
            </div>

            <div class="col-lg-4">
                <label >Ubicación Física *</label>
                <input  class="form-control"  v-model="Equipo.Ubicacion" placeholder="Ubicación del equipo">
                <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.Ubicacion" :Mensaje="'Campo obligatorio'"></Cvalidation></label>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4">
                <label>Marca *</label>
                <input disabled class="form-control"  v-model="Equipo.Marca" placeholder="Marca">
                <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.Marca" :Mensaje="'Campo obligatorio'"></Cvalidation></label>
            </div>
    
            <div class="col-lg-4">
                <label>Modelo *</label>
                <input disabled class="form-control"  v-model="Equipo.Modelo" placeholder="Modelo">
                <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.Modelo" :Mensaje="'Campo obligatorio'"></Cvalidation></label>
            </div>
    
            <div class="col-lg-4">
                <label>País de Origen *</label>
                <input  class="form-control"  v-model="Equipo.Origen" placeholder="Origen">
                <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.Origen" :Mensaje="'Campo obligatorio'"></Cvalidation></label>
            </div>
    
            <div class="col-lg-3">
                <label>Año de Fabricación *</label>
                <!-- <input  class="form-control"  v-model="Equipo.Ano" placeholder="Año"> -->
                <select @change="CalcularAnioReemplazo" name="" id="" class="form-control" v-model="Equipo.Ano">
                    <option value="">Selecciona un Año</option>
                    <option v-for="(item,index) in ListaAnios" :key="index" :value="item">{{ item }}</option>
                </select>
                <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.Ano" :Mensaje="'Campo obligatorio'"></Cvalidation></label>
            </div>

            <div class="col-lg-3">
                <label>Año de Reemplazo *</label>
                <input disabled class="form-control"  v-model="Equipo.AnoReemplazo" placeholder="Año de Reemplazo">
                <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.AnoReemplazo" :Mensaje="'Campo obligatorio'"></Cvalidation></label>
            </div>
    
            <div class="col-lg-6">
                <label >Número de Serie *</label>
                <input  class="form-control"  v-model="Equipo.Serie" placeholder="Serie">
                <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.Serie" :Mensaje="'Campo obligatorio'"></Cvalidation></label>
            </div>
    
            <div class="col-lg-4">
                <label >Tipo de Unidad *</label>
                <select disabled v-model="Equipo.TipoUnidad" class="form-control">
                    <option value="" >Seleccione una opción</option>
                    <option  v-for="(item, index) in ListTipoUnidad" :key="index" :value="item.IdTipoU"  >{{item.Nombre}}</option>
                </select>
                <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.TipoUnidad" :Mensaje="'Campo obligatorio'"></Cvalidation></label>
            </div>
    
            <!-- <div class="col-lg-6">
                <label for="">ID del Activo</label>
                <input @input="get_CodigosActivo()" class="form-control"  v-model="Equipo.ActivoId" placeholder="Código de activación">
                <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.ActivoId" :Mensaje="'Campo obligatorio'"></Cvalidation></label>
                <ul class="list-group position-absolute autocompletar" v-if="ListaCodigos.length>0">
                    <li class="list-group-item"><h3>Selecciona un código</h3></li>
                    <a @click="SeleccionarCodigo(lista.CodigoActivo)" v-for="lista,key,index in ListaCodigos" :key="index" class="list-group-item list-group-item-action" href="#"> {{ lista.CodigoActivo }}</a>
                </ul>
            </div> -->
        
            <div class="col-lg-4">
                <label for="">Código por Cliente</label>
                <input  class="form-control"  v-model="Equipo.ActivoCliente" placeholder="Código del cliente">
                <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.ActivoCliente" :Mensaje="'Campo obligatorio'"></Cvalidation></label>
            </div>
            
            <div class="col-lg-3 form-inline">
                <label for="">Copiar Equipo</label>
                <input v-model="Equipo.Checked" type="checkbox" class="form-control ml-2">
            </div>
            <div v-show="this.Equipo.Checked" class="col-lg-4">
                <label for="">Número de Copias</label>
                <input v-model="Equipo.NumCopias" type="number"  class="form-control" min="1" max="10" placeholder="introduzca un número del 1 al 10">
            </div>
    
            <!-- <div v-if="RutaQr!=''" class="col-lg-4"></div> -->
            <!-- <div class="col-lg-4"></div> -->
            <div v-if="RutaQr!=''" class="col-lg-12 d-flex justify-content-center">
                <img :src="RutaQr">
              </div>
            <!-- <div class="col-lg-4"></div> -->
            <!-- <Cbtnsave>
                <template slot="acciones">
                    <button></button>
                </template>
            </Cbtnsave> -->
            <!-- <template slot="botonesform">aaa</template> -->
        </div>
    </div>
    

</template>
<script>
//El props Id es cuando no es modal y se mando con props
//El export de btnsave es por si no se usa el modal
import Cbtnsave from '@/components/Cbtnsave.vue'
import Cvalidation from '@/components/Cvalidation.vue'

export default {
    name:'Form',
    props:['oClienteSucursalP','poBtnSave'],
    data() {
        return {
            Modal:true,//Sirve pra los botones de guardado
            FormName:'equipamiento',//Sirve para donde va regresar
            Equipo:{
                IdEquipo:0,
                IdCliente:"",
                Ubicacion:"",
                Marca:"",
                Modelo:"",
                Serie:"",
                TipoUnidad:'',
                Ano:"",
                AnoReemplazo:"",
                IdClienteS:"",
                Nequipo:"",
                ActivoId:null,
                ActivoCliente:"",
                Checked:false,
                NumCopias:0
            },
            urlApi:"equipos/recovery",
            ListTipoUnidad:[],
            oClienteSucursal:{},
            RutaQr:"",
            errorvalidacion:[],
            ListaCodigos:[],
            ListaAnios:[],
            TiempoVida:0,
            TipoTiempoVida:''
        }
    },
    components:{
        Cbtnsave,Cvalidation
    },
    methods :
    {
        async Guardar()
        {
             //deshabilita botones
            this.poBtnSave.toast=0; 
            this.poBtnSave.disableBtn=true;

            await this.$http.post(
                'equipos/post',
                this.Equipo,
            ).then( (res) => {
                this.poBtnSave.disableBtn=false;  
                this.poBtnSave.toast=1;
                $('#ModalForm').modal('hide');
                this.bus.$emit('List');
                // sessionStorage.setItem("oEquipo", JSON.stringify(res.data.data.equipos)); 
                this.bus.$emit('ActualizarEquipo',res.data.data.equipos);
                // this.$forceUpdate();
                // window.location.reload();
            }).catch( err => {
                this.errorvalidacion=err.response.data.message.errores;
                this.poBtnSave.disableBtn=false;
                this.poBtnSave.toast=2;  
            });
        },
        Limpiar()
        {
            this.Equipo.IdEquipo= 0;
            this.Equipo.Nombre="";
            this.Equipo.Ubicacion="";
            this.Equipo.Marca="";
            this.Equipo.Modelo="";
            this.Equipo.Serie="";
            this.Equipo.TipoUnidad='';
            this.Equipo.Ano="";
            this.Equipo.AnoReemplazo="";
            this.Equipo.Nequipo="";
            this.Equipo.ActivoId=null;
            this.Equipo.ActivoCliente="";
            this.RutaQr="";
            this.errorvalidacion=[];
            this.ListaCodigos=[];
            this.Equipo.Checked=false;
            this.Equipo.NumCopias=0;
        },
        get_one()
        {
            this.$http.get(
                this.urlApi,
                {
                    params:{IdEquipo: this.Equipo.IdEquipo}
                }
            ).then( (res) => {
                this.Equipo=res.data.data.Equipo;
                this.RutaQr=res.data.data.RutaQr;
            });
        },
        get_tipoUnidad(){
            this.$http.get(
                'tipounidad/get',
                {
                    params:{RegEstatus: 'A',Entrada:10000}
                }
            ).then( (res) => {
                this.ListTipoUnidad=res.data.data.tipounidad
            });
        },
        get_CodigosActivo(){
                this.$http.get(
                'activos/get',
                {
                    params:{
                        // IdClienteS:this.oClienteSucursal.IdClienteS,
                        // CodigoActivo:this.Equipo.ActivoId
                        Todos:true
                    }
                }
                ).then( (res) => {
                    // res.data.data.Activos.forEach(element => {
                    //     this.ListaCodigos.push(element.CodigoActivo);
                    // });
                    // this.ListaCodigos=res.data.data.Activos;
                    this.ListaCodigos = res.data.data.Activos.map(function(obj) {
				        return {
				    	    id: obj.IdActivo,
				    	    label: obj.CodigoActivo
				        };
			        });
                })
        },
        SeleccionarCodigo(){
            // this.Equipo.ActivoId=Codigo;
            // this.ListaCodigos=[];
            // console.log("a");
            this.$http.get('activos/recovery',{
                params:{
                    IdActivo:this.Equipo.ActivoId
                }
            }).then((res)=>{
                this.Equipo.Marca=res.data.data.Activo.Marca;
                this.Equipo.Modelo=res.data.data.Activo.Modelo;
                this.Equipo.TipoUnidad=res.data.data.Activo.IdTipoU;
                this.TiempoVida=res.data.data.Activo.TiempoVida;
                this.TipoTiempoVida=res.data.data.Activo.TipoTiempoVida;
                if (this.Equipo.Ano!='') {
                    this.CalcularAnioReemplazo();
                }
            })
        },
        CalcularAnioReemplazo(){
            let date=new Date(this.Equipo.Ano,0,1);
            let newDate='';
            if (this.TiempoVida!='') {
                switch (this.TipoTiempoVida) {
                    case '1':
                        // let StringDate='01-01-'+this.Equipo.Ano;
                        newDate=new Date(date.setMonth(date.getMonth()+this.TiempoVida));
                        this.Equipo.AnoReemplazo=newDate.getFullYear();
                        break;
                    case '2':
                        this.Equipo.AnoReemplazo=parseInt(date.getFullYear())+parseInt(this.TiempoVida);
                        break;
                }
            }
        }
    },
    created() {
        this.bus.$off('Nuevo');
        let AnioInicio=1980;
        let AnioActual=new Date().getFullYear();

        while (AnioInicio<=AnioActual) {
            this.ListaAnios.push(AnioInicio);
            AnioInicio++;
        }

        if (this.oClienteSucursalP!=undefined)
        {
            sessionStorage.setItem('oClienteSucursal',JSON.stringify(this.oClienteSucursalP));
        }
        this.oClienteSucursal=JSON.parse(sessionStorage.getItem('oClienteSucursal'));
        //Este es para modal
        this.bus.$on('Nuevo',(data,Id)=> 
        {
            this.poBtnSave.disableBtn=false; 
            this.bus.$off('Save');
            this.bus.$on('Save',()=>
            {
                this.Guardar();
            });

            this.get_tipoUnidad();
            this.Limpiar();
            this.Equipo.IdClienteS=this.oClienteSucursal.IdClienteS;
            this.Equipo.IdCliente=this.oClienteSucursal.IdCliente;
            this.get_CodigosActivo();

            if (Id>0)
            {
                this.Equipo.IdEquipo=Id;
                this.get_one();
            }
            this.bus.$emit('Desbloqueo',false);
        });

        if (this.Id!=undefined)
        {
            this.Equipo.IdEquipo=this.Id;
            this.get_one();
        }
    }
}
</script>