<template>
	<div>
		<div class="form-group row">
			<div class="col-lg-3">
				<label>Nombre o Numero del Contrato *</label>
				<input v-model="contract.NumeroC" class="form-control" placeholder="Nombre"/>
				<label id="lblmsuser" style="color:red"
					><Cvalidation v-if="this.errorvalidacion.Numero_Contrato" :Mensaje="errorvalidacion.Numero_Contrato[0]"></Cvalidation
				></label>
			</div>

			<div class="col-lg-3">
				<label>Tipo de Contrato *</label>
				<input v-model="contract.TipoContrato" class="form-control" placeholder="Tipo de Contrato"/>
				<label id="lblmsuser" style="color:red"
					><Cvalidation v-if="this.errorvalidacion.TipoContrato" :Mensaje="errorvalidacion.TipoContrato[0]"></Cvalidation
				></label>
			</div>
			<div class="col-lg-3">
				<label for="">Tipo de Servicio *</label>
				<select v-model="contract.IdConfigS" class="form-control" name="" id="" @change="ListaSubtipo">
					<option value="">Selecciona una opción</option>
					<option v-for="(lista,index) in ListaTipoServicio" :value=lista.IdConfigS :key="index">
						{{ lista.Nombre }}
					</option>
				</select>
				<label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.IdConfigS" :Mensaje="errorvalidacion.IdConfigS[0]" ></Cvalidation
				></label>
			</div>
			<div class="col-lg-3">
				<label for="">SubTipo de Servicio *</label>
				<select v-model="contract.IdTipoSer" class="form-control" name="" id="">
					<option value="">Selecciona una opción</option>
					<option v-for="(lista,index) in ListaSubTipoServicio" :value=lista.IdTipoSer :key="index">
						{{ lista.Concepto }}
					</option>
				</select>
				<label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.IdTipoSer" :Mensaje="errorvalidacion.IdTipoSer[0]" ></Cvalidation
				></label>
			</div>
		</div>

		<div class="form-group row">
			<div class="col-lg-3">
				<label>Valor Total Contrato *</label>
				<vue-numeric
						class="form-control "
						currency="$"
						separator=","
            	        placeholder="$0"
            	        :precision="0"
            	        v-model="contract.Valor"
					></vue-numeric>
				<label id="lblmsuser" style="color:red" ><Cvalidation v-if="this.errorvalidacion.Valor" :Mensaje="errorvalidacion.Valor[0]"></Cvalidation></label>
			</div>
			<div class="col-lg-2">
				<label>Gross Profit *</label>
				<Cporcentaje 
					:activo="false" 
					:minus="true" 
					:clase="'form-control'"
				  	currency="%" 
				  	separator="," 
				  	:precision="2" 
					@input="LimitarGrossProfit"
				  	v-model="contract.GrossProfit"/>
					  <label id="lblmsuser" style="color:red" ><Cvalidation v-if="this.errorvalidacion.GrossProfit" :Mensaje="errorvalidacion.GrossProfit[0]"></Cvalidation></label>
			</div>
			<div class="col-lg-3">
				<label>Ciclo de Facturación *</label>
				<select v-model="contract.Facturacion" class="form-control" name="" id="">
					<option value="">Selecciona una opción</option>
					<option value="Semanal">Semanal</option>
					<option value="Quincenal">Quincenal</option>
					<option value="Mensual">Mensual</option>
					<option value="Bimestral">Bimestral</option>
					<option value="Trimestral">Trimestral</option>
					<option value="Semestral">Semestral</option>
					<option value="Anual">Anual</option>
				</select>
				<!-- <input v-model="contract.Facturacion" class="form-control" placeholder="Facturación"/> -->
				<label id="lblmsuser" style="color:red"
					><Cvalidation v-if="this.errorvalidacion.Facturacion" :Mensaje="errorvalidacion.Facturacion[0]"></Cvalidation
				></label>
			</div>
			<div class="col-lg-2">
				<label>Fecha de Inicio *</label>
				<v-date-picker v-model="contract.FechaInicio" :mode="typeCalendar"
					:popover="{
						placement: 'bottom',
						visibility: 'click'
					}"
					:input-props="{
						class: 'form-control cal-black',
						style: 'cursor:pointer;'
					}"
				/>
				<label id="lblmsuser" style="TipoProceso:red" v-if="this.errorvalidacion.FechaInicio" ><Cvalidation :Mensaje="errorvalidacion.FechaInicio[0]" ></Cvalidation ></label>
			</div>
			<div class="col-lg-2">
				<label for="">Plazo *</label>
				<div class="input-group">
					<input type="text" class="form-control" v-model="contract.Plazo" @input="$onlyNums($event,contract,'Plazo')">
					<select name="" id="" class="form-control w-04" v-model="contract.TipoPlazo">
						<option value="">Seleccione una Opción</option>
						<option value="1">Meses</option>
						<option value="2">Años</option>
					</select>
				</div>
				<label id="lblmsuser" style="TipoProceso:red" v-if="this.errorvalidacion.Plazo" >
					<Cvalidation :Mensaje="errorvalidacion.Plazo[0]" ></Cvalidation >
				</label>
				<label id="lblmsuser" style="TipoProceso:red" v-else-if="this.errorvalidacion.TipoPlazo" >
					<Cvalidation :Mensaje="errorvalidacion.TipoPlazo[0]" ></Cvalidation >
				</label>
			</div>
			<!-- <div class="col-lg-3">
				<label>Fecha Para Finalizar</label>
				<v-date-picker v-model="contract.FechaTermino" :mode="typeCalendar"
					:popover="{
						placement: 'bottom',
						visibility: 'click'
					}"
					:input-props="{
						class: 'form-control cal-black',
						style: 'cursor:pointer;'
					}"
				/>
				<label id="lblmsuser" style="TipoProceso:red" v-if="this.errorvalidacion.FechaTermino" ><Cvalidation :Mensaje="errorvalidacion.FechaTermino[0]" ></Cvalidation ></label>
			</div> -->
		</div>
		<div class="form-group row">
			<div class="col-lg-6">
				<label>Estatus</label>
				<select class="form-control" v-model="contract.Estatus">
					<option value="">Selecciona un Estatus</option>
					<option value="Activo">Activo</option>
					<option value="Inactivo">Inactivo</option>
					<option value="Terminado">Terminado</option>
				</select>
				<!-- <input v-model="contract.Estatus" class="form-control" placeholder="Estatus"/> -->
				<label id="lblmsuser" style="color:red"
					><Cvalidation v-if="this.errorvalidacion.Estatus" :Mensaje="errorvalidacion.Estatus[0]"></Cvalidation
				></label>
			</div>
			<div class="col-lg-6">
				<label>Evidencia</label>
				<div class="custom-file-input-image">
                    <input @change="SubirArchivo()" type="file" accept="application/pdf" ref="file" class="custom-file-input" id="validatedCustomFile" required>
                    <input type="text" v-model="NombreArchivo" class="form-control">
                    <button type="button" class=""><i class="fas fa-paperclip"></i></button>
                </div>
			</div>
		</div>
		<div class="form-group row">
			<div class="col-lg-12">
				<label>Comentarios</label>
				<textarea v-model="contract.Comentario" placeholder=" Coloque sus comentarios" class="form-control" cols="2" rows="3"
			></textarea>
			</div>
		</div>
		<!-- <div class="col-lg-8">
			<label>Nombre o Numero del Contrato</label>
			<input
				v-model="contract.NumeroC"
				class="form-control"
				placeholder="Nombre"
			/>
			<label id="lblmsuser" style="color:red"
				><Cvalidation
					v-if="this.errorvalidacion.Numero_Contrato"
					:Mensaje="errorvalidacion.Numero_Contrato[0]"
				></Cvalidation
			></label>
		</div>
		<div class="col-lg-8">
			<label for="">Tipo de Contrato</label>
			<select v-model="contract.IdConfigS" class="form-control" name="" id="">
				<option value="">Selecciona una opción</option>
				<option v-for="(lista,index) in ListaTipoServicio" :value=lista.IdConfigS :key="index">
					{{ lista.Nombre }}
				</option>
			</select>
			<label id="lblmsuser" style="color:red"
				><Cvalidation
					v-if="this.errorvalidacion.IdConfigS"
					:Mensaje="errorvalidacion.IdConfigS[0]"
				></Cvalidation
			></label>
		</div>
		<div class="col-lg-8">
			<label>Valor del Contrato</label>
			<vue-numeric
					class="form-control "
					currency="$"
					separator=","
                    placeholder="$0.00"
                    :precision="2"
                    v-model="contract.Valor"
				></vue-numeric>
			<label id="lblmsuser" style="color:red"
				><Cvalidation
					v-if="this.errorvalidacion.Valor"
					:Mensaje="errorvalidacion.Valor[0]"
				></Cvalidation
			></label>
		</div>
		<div class="col-lg-8">
			<label>Comentarios</label>
			<textarea
				v-model="contract.Comentario"
				placeholder=" Coloque sus comentarios"
				class="form-control"
				cols="2"
				rows="3"
			></textarea>
		</div> -->
	</div>
</template>

<script>
//El props Id es cuando no es modal y se mando con props
//El export de btnsave es por si no se usa el modal
import Cbtnsave from "@/components/Cbtnsave.vue";
import Cvalidation from "@/components/Cvalidation.vue";
export default {
	name: "Form",
	props: ["IdContrato", "pIdClienteS", "NameList", "poBtnSave"],
	data() {
		return {
			Modal: true, //Sirve pra los botones de guardado
			FormName: "cliente", //Sirve para donde va regresar
			contract: {
				IdContrato: 0,
				IdClienteS: 0,
				NumeroC: "",
				Comentario: "",
				IdProyectoSpend: 0,
				IdConfigS:"",
				Valor:"",
				TipoContrato:"",
				FechaInicio:"",
				FechaTermino:"",
				Facturacion:"",
				Archivo:"",
				Estatus:"",
				IdTipoSer:"",
				GrossProfit:0,
				Plazo:"",
				TipoPlazo:""
			},
			EmitSeccion:this.poBtnSave.EmitSeccion,
			errorvalidacion: [],
			checked: false,
			checkedScanning: false,
			ListaContratos: [],
			ListaTipoServicio:[],
			ListaSubTipoServicio:[],
			NombreArchivo:"Elegir Archivo (5MB)"
			// EmitSeccion:this.poBtnSave.EmitSeccion
		};
	},
	components: {
		Cbtnsave,
		Cvalidation
	},
	methods: {
		async Guardar() {
			//deshabilita botones
			let Operacion="";
			if (this.contract.IdContrato=="" ? Operacion="Crear" : Operacion="Editar");
			this.poBtnSave.toast = 0;
			this.poBtnSave.disableBtn = true;
			let FechaInicio='';
			// let FechaTermino='';
			let archivo=this.$refs.file.files[0];
			if (this.contract.FechaInicio !='' && this.contract.FechaInicio!=undefined) {
				let day=this.contract.FechaInicio.getDate();
                let month=this.contract.FechaInicio.getMonth()+1;
                let year=this.contract.FechaInicio.getFullYear();
                FechaInicio=year+'-'+month+'-'+day;
			}
			// if (this.contract.FechaTermino !='' && this.contract.FechaTermino!=undefined) {
			// 	let day=this.contract.FechaTermino.getDate();
            //     let month=this.contract.FechaTermino.getMonth()+1;
            //     let year=this.contract.FechaTermino.getFullYear();
            //     FechaTermino=year+'-'+month+'-'+day;
			// }
			let formData=new FormData();
			formData.set('IdContrato',this.contract.IdContrato);
			formData.set('IdClienteS',this.contract.IdClienteS);
			formData.set('NumeroC',this.contract.NumeroC);
			formData.set('Comentario',this.contract.Comentario);
			formData.set('IdProyectoSpend',this.contract.IdProyectoSpend);
			formData.set('IdConfigS',this.contract.IdConfigS);
			formData.set('IdTipoSer',this.contract.IdTipoSer);
			formData.set('Valor',this.contract.Valor);
			formData.set('TipoContrato',this.contract.TipoContrato);
			formData.set('FechaInicio',FechaInicio);
			// formData.set('FechaTermino',FechaTermino);
			formData.set('Plazo',this.contract.Plazo);
			formData.set('TipoPlazo',this.contract.TipoPlazo);
			formData.set('Facturacion',this.contract.Facturacion);
			formData.set('Archivo',this.contract.Archivo);
			formData.append('ArchivoFile',archivo);
			formData.set('Estatus',this.contract.Estatus);
			formData.set('GrossProfit',this.contract.GrossProfit);
			this.$http.post("numcontrato/post", formData)
				.then(res => {
					if (this.contract.IdContrato==0) {
						// MODIFICAR LA VARIABLE DE SESION PARA QUE HAYA CONCORDANCIA
						if (JSON.parse(sessionStorage.getItem("IdSaved")).Contratos!=null) {
							let Obj=JSON.parse(sessionStorage.getItem("IdSaved"));
							Obj.Contratos++;
							sessionStorage.setItem("IdSaved",JSON.stringify(Obj));
						}	
					}
					this.poBtnSave.disableBtn = false;
					this.poBtnSave.toast = 1;
					$("#Contratos").modal("hide");
					this.bus.$emit("List",Operacion,"Contrato");
				})
				.catch(err => {
					this.errorvalidacion = err.response.data.message.errores;
					this.poBtnSave.disableBtn = false;
					this.poBtnSave.toast = 2;
				});
		},
		Limpiar() {
			this.contract = {
				IdContrato: 0,
				IdClienteS: 0,
				NumeroC: "",
				Comentario: "",
				IdProyectoSpend: 0,
				IdConfigS:"",
				Valor:"",
				TipoContrato:"",
				FechaInicio:"",
				FechaTermino:"",
				Facturacion:"",
				Archivo:"",
				Estatus:"",
				IdTipoSer:"",
				GrossProfit:0,
				Plazo:"",
				TipoPlazo:""
			};
			this.errorvalidacion = [""];
			this.NombreArchivo="Elegir Archivo (5MB)";
		},
		get_one() {
			this.$http
				.get("numcontrato/recovery", {
					params: { IdContrato: this.contract.IdContrato }
				})
				.then(res => {
					this.contract = res.data.data.contrato;
					let formatedDateInicio=res.data.data.contrato.FechaInicio.replace(/-/g, "\/");
					this.contract.FechaInicio=new Date(formatedDateInicio);
					let formatedDateTermino=res.data.data.contrato.FechaTermino.replace(/-/g, "\/");
					this.contract.FechaTermino=new Date(formatedDateTermino);
					this.NombreArchivo=res.data.data.contrato.Archivo;
					this.ListaSubtipo();
				});
		},
		ListTipoContrato(){
			this.$http.get("configservicio/get",
			{
				params:
				{
					RegEstatus:"A"
				}
			}).then((res)=>{
				this.ListaTipoServicio=res.data.data.configservicio
			})
		},
		async ListaSubtipo() {
			await this.$http.get('tiposervicio/get',{
				params:{
					RegEstatus:'A',
					IdConfigS:this.contract.IdConfigS,
					// IdTipoServ:this.contract.IdTipoServ,
					Entrada:''
				}
			}).then( (res) => {
				this.ListaSubTipoServicio =res.data.data.tiposervicio;
			});
		},
		SubirArchivo(){
			const image = this.$refs.file.files[0];

            var FileSize = image.size / 1024 / 1024; // in MB
            if (FileSize > 5) {
                this.$toast.info('Solo se puede subir archivos menores a 5 MB');
                const  input  = this.$refs.file;
                input .type = 'text'
                input .type = 'file';
                return false;
            }
                        
            var allowedExtensions = /(\.pdf|\.PDF)$/i;
            if(!allowedExtensions.exec(image.name)){
                this.$toast.info('Extenciones permitidas .pdf');
                const  input  = this.$refs.file;
                input.type = 'text'
                input.type = 'file';
                this.NombreArchivo='Elegir archivo (5MB)';
                return false;
            }

            this.NombreArchivo=image.name;
		},
		LimitarGrossProfit(){
			if (parseInt(this.contract.GrossProfit)>100) {
				this.contract.GrossProfit='0';
			}
		}
	},
	created() {
		this.ListTipoContrato();
		this.bus.$off("NewModal_"+this.EmitSeccion);

		//Este es para modal
		this.bus.$on("NewModal_"+this.EmitSeccion, (data, Id) => {
			this.Limpiar();
			this.contract.IdClienteS = this.pIdClienteS;

			this.poBtnSave.disableBtn = false;
			this.bus.$off("Save_"+this.EmitSeccion);
			this.bus.$on("Save_"+this.EmitSeccion, () => {
				this.Guardar();
			});

			if (Id > 0) {
				this.contract.IdContrato = Id;
				this.get_one();
			}

			this.ListaContratos = [];

			this.bus.$emit("Desbloqueo", false);
		});
	},
	computed:{
		typeCalendar: function() {
			return "single";
		},
	}
};
</script>
