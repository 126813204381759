<template>
    <div>
        <CLoader :pConfigLoad="ConfigLoad">
			<template slot="BodyFormLoad">
                
                <div class="row">
                    <div class="col-lg-3">
                        <div class="avatar-upload-histequipo">
                            <div class="avatar-edit">
                                <input id="file" @change="CambiarImagen($event)" ref="file" type="file" name="file" accept=".png, .jpg, .jpeg">
                                <label for="file"></label>
                            </div>
                            <div class="avatar-edit2">
                                <button type="button" data-backdrop="static"
                                data-toggle="modal"
                                data-target="#imagen" @click="ZoomImagen(Activo.Imagen1)">
                                    <i class="fas fa-search"></i>
                                </button>
                            </div>
                            <!-- <div v-if="BndImagenEquipo!=0" class="avatar-edit3">
                                <button type="button" @click="GuardarImagenEquipo">
                                    <i class="fas fa-save"></i>
                                </button>
                            </div> -->
                            <div class="avatar-preview">
                                <div id="imagePreview" :style="'background-image: url('+Activo.Imagen1+');'" :src="Activo.Imagen1">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3">

                        <label for="">Tipo de Unidad *</label>
                        <!-- <input type="text" class="form-control"> -->
                        <select v-model="Activo.IdTipoU" class="form-control">
                            <option value="" >Seleccione una opción</option>
                            <option v-for="(item,index) in ListaTipoUnidad" :key="index" :value="item.IdTipoU">{{ item.Nombre }}</option>
                        </select>
                        <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.IdTipoU" :Mensaje="'Campo obligatorio'"></Cvalidation></label>

                        
                        <br>

                        <label for="">Marca *</label>
                        <input type="text" class="form-control" v-model="Activo.Marca" placeholder="Marca">
                        <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.Marca" :Mensaje="'Campo obligatorio'"></Cvalidation></label>
                        
                        <br>

                        <label for="">Valor Aproximado</label>
                        <vue-numeric
                            class="form-control "
                            currency="$"
                            separator=","
                            placeholder="$0.00"
                            :precision="2"
                            v-model="Activo.Valor"
                        ></vue-numeric>
                        
                    </div>
                    <div class="col-lg-3">
                        <label for="">Sub Tipo de Unidad</label>
                        <input type="text" class="form-control" v-model="Activo.Voltaje" placeholder="Sub Tipo de Unidad">
                        
                        <br>
                        
                        <label for="">Modelo *</label>
                        <input @input="SugerenciaIdActivo" type="text" class="form-control" v-model="Activo.Modelo" placeholder="Modelo">
                        <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.Modelo" :Mensaje="'Campo obligatorio'"></Cvalidation></label>
                        
                        <br>
                        
                        <label for="">Fecha Actualización de Valor *</label>
                        <v-date-picker v-model="Activo.FechaActValor" :mode="typeCalendar"
                            :popover="{
                                placement: 'bottom',
                                visibility: 'click'
                            }"
                            :input-props="{
                                class: 'form-control cal-black',
                                style: 'cursor:pointer;'
                            }"
                        />
                        <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.FechaActValor" :Mensaje="'Campo obligatorio'"></Cvalidation></label>

                        <br>

                        <!-- <vue-numeric
                            class="form-control "
                            placeholder="0"
                            v-model="Activo.Capacidad"
                        ></vue-numeric> -->
                    </div>
                    <div class="col-lg-3">

                        <label for="">Capacidad</label>
                        <input type="text" class="form-control" v-model="Activo.Capacidad">
                        
                        <br>

                        <label for="">Expectativa de vida *</label>
                        <div class="input-group">
                            <input type="text" class="form-control" v-model="Activo.TiempoVida" @input="$onlyNums($event,Activo,'TiempoVida')">
                            <select name="" id="" class="form-control" v-model="Activo.TipoTiempoVida">
                                <option value="">Seleccione una Opción</option>
                                <option value="1">Meses</option>
                                <option value="2">Años</option>
                            </select>
                        </div>
                        <Cvalidation v-if="this.errorvalidacion.TipoTiempoVida" :Mensaje="'Campo obligatorio'"></Cvalidation>
                        <!-- <vue-numeric
                            class="form-control "
                            placeholder="Expectativa de  Vida"
                            v-model="Activo.TiempoVida"
                        ></vue-numeric> -->
                        

                        <br>
                        <label class="bold" style="color:#0F3F87" for="">ID Equipo *</label>
                        <input type="text" class="form-control" v-model="Activo.CodigoActivo" placeholder="ID del Activo">
                        <label id="lblmsuser" style="color:red">
                            <Cvalidation v-if="this.errorvalidacion.CodigoActivo" :Mensaje="'Campo obligatorio'"></Cvalidation>
                            
                        </label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-8">
                        <label for="">Descripción</label>
                        <textarea class="form-control" cols="30" rows="2" v-model="Activo.Descripcion"></textarea>
                    </div>
                    <div class="col-lg-4">
                        <label for="">Accesorios</label>
                        <textarea class="form-control" cols="30" rows="2" v-model="Activo.Accesorios"></textarea>
                    </div>
                </div>
                <!-- BOTON DE GUARDAR Y CONTINUAR Y GUARDAR Y SALIR -->
                <div class="row mt-2 mb-2">
                    <div class="col-lg-12 d-flex justify-content-end">
                        <!-- <button @click="Guardar(1)" class="btn btn-01 mr-2" type="button"><i class="fa fa-save"></i> Guardar y Salir</button> -->
                        <button v-if="!Guardado" @click="Guardar(2)" class="btn btn-01 mr-2" type="button"> <i class="fa fa-plus-circle"></i> Guardar y Continuar</button>
                    </div>
                </div>

                <!-- AQUI VAN LOS TABS FALSOS -->
                <div v-if="Activo.IdActivo>0">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="form-inline">
                                <button @click="CambiarPestania('Documentos')" type="button" :class="Botones.Documentos ? 'btn btn-06 text-info bold mt-0 pt-0' : 'btn btn-06 text-dark bold mt-0 pt-0'">Documentos</button>
                                <button @click="CambiarPestania('Refacciones')" type="button" :class="Botones.Refacciones ? 'btn btn-06 text-info bold mt-0 pt-0' : 'btn btn-06 text-dark bold mt-0 pt-0'">Refacciones</button>
                                <button @click="CambiarPestania('Programa')" type="button" :class="Botones.Programa ? 'btn btn-06 text-info bold mt-0 pt-0' : 'btn btn-06 text-dark bold mt-0 pt-0'">Mantenimiento</button>
                            </div>
                        </div>
                    </div>
                    
                    <div v-if="Botones.Documentos || Botones.Programa">
                        <ListForm 
                            :ShowButtonTop="DatosTablaDocumentos.ShowButtonTop"
                            :ShowButton="((Botones.Documentos && ListaDocumentos.length == 5) || (Botones.Programa && ListaProgramas.length == 5)) ? false : DatosTablaDocumentos.ShowButton"
                            :NombreTabla="DatosTablaDocumentos.NombreTabla"
                            :EmitSeccionTabla="DatosTablaDocumentos.EmitSeccionTabla"
                            :TituloBoton="DatosTablaDocumentos.TituloBoton"
                            :pConfigLoad="DatosTablaDocumentos.ConfigLoad"
                        >
                        <template slot="header_table_form">
                            <th class="tw-5">Título</th>
                            <th class="tw-4">Descripción</th>
                            <th class="tw-3 text-right">Fecha de Registro</th>
                            <th class="tw-2 m-0 p-0 text-right">Acciones</th>
                        </template>
                        
                        <template slot="body_table_form" v-if="Botones.Documentos">
                            <tr v-for="(item,index) in ListaDocumentos" :key="index">
                                <td class="tw-5">{{ item.Titulo }}</td>
                                <td class="tw-4">{{ item.Descripcion }}</td>
                                <td class="tw-3 text-right"> <i class="fa fa-calendar"></i> {{ $getCleanDate(item.FechaReg,false) }}</td>
                                <td class="tw-2 text-right" :colspan="ListaDocumentos.length<5 ? 2 : 1">
                                    <Cbtnaccion :isModal="true" 
                                    :pEmitSeccion="DatosTablaDocumentos.EmitSeccionTabla" 
                                    :Id="item.IdDocumento"
                                    :IrA="DatosTablaDocumentos.TituloBoton">
                                        <template slot="btnaccion">
                                            <button
                                                v-b-tooltip.hover.lefttop
                                                v-if="!item.NombreArchivo == ''"
                                                @click="get_pdfdocumento(item.NombreArchivo)"
                                                title="Ver PDF"
                                                type="button"
                                                class="btn-icon mr-2"
                                            >
                                                <i class="fa fa-file-pdf"></i>
                                            </button>
                                        </template>
                                    </Cbtnaccion>
                                </td>
                            </tr>
                        </template>
                        <template slot="body_table_form" v-if="Botones.Programa">
                            <tr v-for="(item,index) in ListaProgramas" :key="index">
                                <td>{{ item.Titulo }}</td>
                                <td>{{ item.Descripcion }}</td>
                                <td class="text-right"> <i class="fa fa-calendar"></i> {{ $getCleanDate(item.FechaReg,false) }}</td>
                                <td class="text-right" :colspan="ListaProgramas.length<5 ? 2 : 1">
                                    <Cbtnaccion :isModal="true" 
                                    :pEmitSeccion="DatosTablaDocumentos.EmitSeccionTabla" 
                                    :Id="item.IdDocumento"
                                    :IrA="DatosTablaDocumentos.TituloBoton">
                                        <template slot="btnaccion">
                                            <button
                                                v-b-tooltip.hover.lefttop
                                                v-if="!item.NombreArchivo.substr(item.NombreArchivo.lastIndexOf('/')+1) == ''"
                                                @click="get_pdfdocumento(item.NombreArchivo)"
                                                title="Ver PDF"
                                                type="button"
                                                class="btn-icon mr-2"
                                            >
                                                <i class="fa fa-file-pdf"></i>
                                            </button>
                                        </template>
                                    </Cbtnaccion>
                                </td>
                            </tr>
                        </template>
                        </ListForm>
                    </div>

                    <div v-if="Botones.Refacciones">
                        <ListForm
                            :ShowButtonTop="DatosTablaRefacciones.ShowButtonTop"
                            :ShowButton="ListaRefacciones.length>=10 ? false : DatosTablaRefacciones.ShowButton"
                            :NombreTabla="DatosTablaRefacciones.NombreTabla"
                            :EmitSeccionTabla="DatosTablaRefacciones.EmitSeccionTabla"
                            :TituloBoton="DatosTablaRefacciones.TituloBoton"
                            :pConfigLoad="DatosTablaRefacciones.ConfigLoad"
                        >
                            <template slot="header_table_form">
                                <th>Nombre</th>
                                <th>Marca</th>
                                <th>Modelo</th>
                                <th>Cantidad</th>
                                <th>N° Parte</th>
                                <th>O/R</th>
                                <th>Reemplazo</th>
                                <th class="text-right">Acciones</th>
                            </template>

                            <template slot="body_table_form">
                                <tr v-for="(item,key,index) in ListaRefacciones" :key="index">
                                    <td>{{ item.Descripcion }}</td>
                                    <td>{{ item.Marca }}</td>
                                    <td>{{ item.Modelo }}</td>
                                    <td>{{ item.Cantidad }}</td>
                                    <td>{{ item.NumParte }}</td>
                                    <td v-if="item.Tipo==1">Genérico</td>
                                    <td v-else>Original</td>
                                    <td>{{ item.Periodo }} {{ item.TipoPeriodo == '1' ? 'Meses' : item.TipoPeriodo == '2' ? 'Años' : '' }}</td>
                                    <td class="text-right" :colspan="ListaRefacciones.length < 10 ? 2 : 1">
                                        <Cbtnaccion
                                        :isModal="true" 
                                        :pEmitSeccion="DatosTablaRefacciones.EmitSeccionTabla" 
                                        :Id="item.IdRefaccion"
                                        :IrA="DatosTablaRefacciones.TituloBoton"  
                                        >
                                            <template slot="btnaccion">
                                                <button
                                                    v-b-tooltip.hover.lefttop
                                                    v-if="!item.Archivo.substr(item.Archivo.lastIndexOf('/')+1) == ''"
                                                    @click="get_pdfdocumento(item.Archivo)"
                                                    title="Ver PDF"
                                                    type="button"
                                                    class="btn-icon mr-2"
                                                >
                                                    <i class="fa fa-file-pdf"></i>
                                                </button>
                                            </template>
                                        </Cbtnaccion>
                                    </td>
                                </tr>
                            </template>
                        </ListForm>
                    </div>
                </div>

            </template>
        </CLoader>
        
        
        <Modal :Showbutton="false" :NameModal="'imagen'" :Nombre="'Imagen'">
            <template slot="Form">
				<div class="form-row justify-content-center align-items-center">
					<img :src="ImagenAMostrar" alt="Aún no hay una imagen para mostrar" class="img-thumbnail">
				</div>
			</template>
        </Modal>

        <Modal 
            :NameModal="DatosTablaDocumentos.TituloBoton" 
            :size="SizeDocs" 
            :Nombre="Botones.Documentos ? DatosTablaDocumentos.TituloBoton : 'Mantenimiento'" 
            :pEmitSeccion="DatosTablaDocumentos.EmitSeccionTabla"
            :poBtnSave="DatosTablaDocumentos.oBtnSave"
        >
            <template slot="Form">
                <FormDocumentos :PrimerRegistro="ListaProgramas[0] !== undefined ? ListaProgramas[0]:0" :CantidadRegistros="ListaProgramas.length" :poBtnSave="DatosTablaDocumentos.oBtnSave" :IdActivo="Activo.IdActivo" 
                :NombreTabla="Botones.Documentos ? DatosTablaDocumentos.NombreTabla : 'Programas'"></FormDocumentos>
            </template>
        </Modal>

        <Modal 
            :NameModal="DatosTablaRefacciones.TituloBoton" 
            :size="SizeRefacciones" 
            :Nombre="DatosTablaRefacciones.TituloBoton" 
            :pEmitSeccion="DatosTablaRefacciones.EmitSeccionTabla"
            :poBtnSave="DatosTablaRefacciones.oBtnSave"
        >
            <template slot="Form">
                <FormRefacciones :poBtnSave="DatosTablaRefacciones.oBtnSave" :IdActivo="Activo.IdActivo"></FormRefacciones>
            </template>
        </Modal>
    </div>
</template>

<script>
import moment from "moment";
import Cbtnsave from '@/components/Cbtnsave.vue';
import Cvalidation from '@/components/Cvalidation.vue';
import Modal from '@/components/Cmodal.vue';
import ListForm from '../../../components/Clistform.vue';
import FormDocumentos from '../activos/manuales/Form.vue';
import FormRefacciones from '../activos/refacciones/Form.vue';
import Cbtnaccion from "@/components/Cbtnaccion.vue";
import Config from "@/helpers/VarConfig.js";
export default{
    name:'Form',
    props:["poBtnSave"],
    components:{
        Cbtnsave,
        Cvalidation,
        ListForm,
        Modal,
        FormDocumentos,
        FormRefacciones,
        Cbtnaccion
    },
    data(){
        return{
            Modal:true,
            SizeDocs:'modal-md',
            SizeRefacciones:'modal-lg',
            FormName:'activo',
            EmitSeccion:this.poBtnSave.EmitSeccion,
            ValidElement:Config.validImage2m,
            Activo:{
                IdActivo:'',
                IdTipoU:"",
                Marca:'',
                Modelo:'',
                Capacidad:0,
                Descripcion:'',
                Accesorios:'',
                CodigoActivo:'',
                Valor:0,
                Voltaje:'',
                TiempoVida:0,
                TipoTiempoVida:'1',
                FechaActValor:'',
                Imagen1:''
            },
            Guardado:false,
            Botones:{
                Documentos:true,
                Refacciones:false,
                Programa:false
            },
            ListaTipoUnidad:[],
            ListaDocumentos:[],
            ListaRefacciones:[],
            ListaProgramas:[],
            errorvalidacion:[],
            ImagenAMostrar:'',
            UrlDocumentos:'',
            ValorActivoAnterior:'',
            // PARA TODAS LAS TABLAS DENTRO DEL FORM
            DatosTablaDocumentos:{
                ConfigLoad:{
                    ShowLoader:true,
                    ClassLoad:true
                },
                ShowButtonTop:false,
                ShowButton:true,
				NombreTabla:"Manuales",
				EmitSeccionTabla:"Documentos",
				TituloBoton:"Documentos",
				oBtnSave:{
                	isModal:true,
                	disableBtn:false,
                	toast:0,
                	nombreModal:"Documentos",
                	EmitSeccion:"Documentos"
            	}
            },
            DatosTablaRefacciones:{
                ConfigLoad:{
                    ShowLoader:true,
                    ClassLoad:true
                },
                ShowButtonTop:false,
                ShowButton:true,
                NombreTabla:"Refacciones",
                EmitSeccionTabla:"Refacciones",
                TituloBoton:"Refacciones",
                oBtnSave:{
                    isModal:true,
                    disableBtn:false,
                	toast:0,
                	nombreModal:"Refacciones",
                	EmitSeccion:"Refacciones"
                }
            },
            ConfigLoad:{
				ShowLoader: false,
				ClassLoad:  true,
			},
        }
    },
    methods: {
        Guardar(camino) {
            this.ConfigLoad.ShowLoader = true;

            let Fecha='';
            let image=this.$refs.file.files[0];
            if (this.Activo.FechaActValor!='' && this.Activo.FechaActValor!=undefined) {
                let day=this.Activo.FechaActValor.getDate();
                let month=this.Activo.FechaActValor.getMonth()+1;
                let year=this.Activo.FechaActValor.getFullYear();
                Fecha=year+'-'+month+'-'+day;   
            }
            let formData=new FormData();
            formData.set('IdActivo',this.Activo.IdActivo);
            formData.set('IdTipoU',this.Activo.IdTipoU)
            formData.set('Marca',this.Activo.Marca);
            formData.set('Modelo',this.Activo.Modelo);
            formData.set('Capacidad',this.Activo.Capacidad);
            formData.set('Descripcion',this.Activo.Descripcion);
            formData.set('CodigoActivo',this.Activo.CodigoActivo);
            formData.set('Valor',this.Activo.Valor);
            formData.set('Voltaje',this.Activo.Voltaje);
            formData.set('TiempoVida',this.Activo.TiempoVida);
            formData.set('TipoTiempoVida',this.Activo.TipoTiempoVida);
            formData.set('FechaActValor',Fecha);
            formData.set('Accesorios',this.Activo.Accesorios);
            formData.append('Imagen1',image);

            if (this.Activo.Imagen1!="") {
                let index=this.Activo.Imagen1.lastIndexOf("/")+1;
				let cadena=this.Activo.Imagen1.substr(index);
                formData.set('ImagenPrevia1','/'+cadena);
            }

            this.$http.post('activos/post',formData,{
                headers:{
                    'Content-Type':'multipart/form-data'
                }  
            }).then((res)=>{
                this.poBtnSave.disableBtn = false;
				this.poBtnSave.toast = 1;
                switch (camino) {
                    case 1:
                        $('#activo').modal('hide');
                        this.bus.$emit('List');       
                        break;
                
                    case 2:
                        this.Activo.IdActivo=res.data.data.Activo.IdActivo;
                        this.bus.$emit('List');
                        this.GetDocumentos(1);
                        this.Guardado=true;
                        this.errorvalidacion=[];
                        break;
                }
            }).catch( err => {
                this.errorvalidacion=err.response.data.message.errores;
                this.poBtnSave.disableBtn=false;
                this.poBtnSave.toast=2;

            }).finally(() => {
                this.ConfigLoad.ShowLoader = false;
            });
        },

        Limpiar(){
            this.errorvalidacion=[];
            this.ListaDocumentos=[];
            this.ListaRefacciones=[];
            this.ListaProgramas=[];
            this.Activo={
                IdActivo:'',
                IdTipoU:"",
                Marca:'',
                Modelo:'',
                Capacidad:0,
                Descripcion:'',
                Accesorios:'',
                CodigoActivo:'',
                Valor:0,
                Voltaje:'',
                TiempoVida:0,
                TipoTiempoVida:'1',
                FechaActValor: new Date(),
                Imagen1:''
            };
            this.Botones={
                Documentos:true,
                Refacciones:false,
                Programa:false
            };
            this.DatosTablaDocumentos.NombreTabla='Manuales';
            if (localStorage.getItem("fechacalendario")) {
				let NuevaFecha = localStorage.getItem("fechacalendario");
				let formatedDate = NuevaFecha.replace(/-/g, "\/");
				this.Activo.FechaActValor = new Date(formatedDate);
			}
        },

        get_one(){
            this.ConfigLoad.ShowLoader = true;
            this.$http.get('activos/recovery',{
                params:{
                    IdActivo:this.Activo.IdActivo
                }
            }).then((res)=>{
                const objres=res.data.data.Activo;
                this.Activo.IdTipoU=objres.IdTipoU;
                this.Activo.Marca=objres.Marca;
                this.Activo.Modelo=objres.Modelo;
                this.Activo.Capacidad=objres.Capacidad;
                this.Activo.Descripcion=objres.Descripcion;
                this.Activo.Accesorios=objres.Accesorios;
                this.Activo.CodigoActivo=objres.CodigoActivo;
                this.Activo.Valor=objres.Valor;
                this.Activo.Voltaje=objres.Voltaje;
                this.Activo.TiempoVida=objres.TiempoVida;
                this.Activo.TipoTiempoVida=objres.TipoTiempoVida;
                this.Activo.Imagen1=res.data.data.Imagen1;
                let formatedDate = objres.FechaActValor.replace(/-/g, "\/");
                this.Activo.FechaActValor=new Date(formatedDate);

            }).finally(() => {
                this.ConfigLoad.ShowLoader = false;
            });
        },

        GetTipoUnidad(){
            this.$http.get(
                'tipounidad/get',
                {
                    params:{RegEstatus: 'A',Entrada:10000}
                }
            ).then( (res) => {
                this.ListaTipoUnidad=res.data.data.tipounidad
            });
        },
        CambiarImagen(target){
            this.$uploadImagePreview(target,this.ValidElement, Array('Img','imagePreview'));
        },
        ZoomImagen(Imagen){
            if (this.$refs.file.files[0]!=undefined) {
				let ImagenPreview=document.getElementById("imagePreview");
				const Estilos=window.getComputedStyle(ImagenPreview);
				let CadenaBase=Estilos.backgroundImage;
				let Url=CadenaBase.slice(5,-2);
				this.ImagenAMostrar=Url;
			}
			else{
				this.ImagenAMostrar=Imagen;
			}
        },
        MinimoFecha(){
            let fecha='';
			if(this.Activo.FechaActValor!=""){
				var FechaAnterior=new Date(this.Activo.FechaActValor);
				var Actual=new Date();
				if (FechaAnterior.getTime()>=Actual.getTime()) {
					fecha=new Date();
				}
				else{
					fecha=moment(this.Activo.FechaActValor).add(1,'day').format('YYYY-MM-DD');
					fecha=new Date(fecha);
				}
				
			}
			else{
				fecha=new Date();
			}
			return fecha;
        },
        CambiarPestania(index){
            let Botones=this.Botones;
			Object.keys(Botones).forEach(function(key,ind){
				if (key==index) {
					Botones[key]=true;
				}
				else{
					Botones[key]=false;
				}
				// console.log(index)
			});
            if (this.Botones.Documentos) {
                this.DatosTablaDocumentos.NombreTabla='Manuales';
            }
            if (this.Botones.Programa) {
                this.DatosTablaDocumentos.NombreTabla='Programas';
            }
        },
        get_pdfdocumento(Nombre){
            let archivo = Nombre;
			window.open(
				archivo,
				"_blank",
				"toolbar=1, scrollbars=1, resizable=1, width=" +
					1015 +
					", height=" +
					800
			);
        },
        BorrarDocumento(Id){
            this.$swal({
                title: 'Esta seguro que desea eliminar este dato?',
                text: 'No se podra revertir esta acción',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si',
                cancelButtonText: 'No, mantener',
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result)=>{
                if(result.value){
                    this.$http.delete(
                        'manuales/'+Id
                    ).then((res)=>{
                        this.$toast.success('Información eliminada');
                        this.GetDocumentos(1);
                        this.GetDocumentos(2);
                    })
                }
            })
        },
        BorrarRefaccion(Id){
            this.$swal({
                title: 'Esta seguro que desea eliminar este dato?',
                text: 'No se podra revertir esta acción',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si',
                cancelButtonText: 'No, mantener',
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result)=>{
                if(result.value){
                    this.$http.delete(
                        'refacciones/'+Id
                    ).then((res)=>{
                        this.$toast.success('Información eliminada');
                        this.GetRefacciones();
                    })
                }
            })
        },
        SugerenciaIdActivo(){
            // this.Activo.CodigoActivo=this.CodigoActivo;
            let substr=this.Activo.Modelo.substring(0,(this.Activo.Modelo.length)-1);
            let pattern=new RegExp(substr,"gi");
            // if (substr.includes(this.Activo.CodigoActivo)) {
            if (pattern.test(this.Activo.CodigoActivo)) {
                this.Activo.CodigoActivo=this.Activo.Modelo;
            }
        },
        // LISTAS DE TABLAS
        async GetDocumentos(Tipo){
            await this.$http.get('manuales/get',{
                params:{
                    IdActivo:this.Activo.IdActivo,
                    Tipo:Tipo
                }
            }).then((res)=>{
                if (Tipo==1) {
                    this.ListaDocumentos=res.data.data.documentos;   
                }
                else{
                    this.ListaProgramas=res.data.data.documentos;
                }
                this.UrlDocumentos=res.data.data.urlarchivos;
            }).finally(()=>{
                this.DatosTablaDocumentos.ConfigLoad.ShowLoader=false;
            })
        },
        async GetRefacciones(){
            await this.$http.get('refacciones/get',{
                params:{
                    IdActivo:this.Activo.IdActivo
                }
            }).then((res)=>{
                this.ListaRefacciones=res.data.data.refacciones;
                // this.UrlDocumentos=res.data.data.urlarchivos;
            }).finally(()=>{
                this.DatosTablaRefacciones.ConfigLoad.ShowLoader=false;
            })
        },
    },
    created(){
        // console.log("a");
        this.GetTipoUnidad();
        this.bus.$off("NewModal_"+this.EmitSeccion);
        this.bus.$on("NewModal_"+this.EmitSeccion,(data,Id)=>{
            // console.log("a");
            this.Guardado=false;
            this.poBtnSave.disableBtn = false;
			this.bus.$off("Save_"+this.EmitSeccion);
			this.bus.$on("Save_"+this.EmitSeccion, (camino) => {
				this.Guardar(camino);
			});
            this.Limpiar();
			if (Id > 0) {
				this.Activo.IdActivo = Id;
				this.get_one();
                this.GetDocumentos(1);
                this.GetRefacciones();
                this.GetDocumentos(2);
                this.Guardado=true;
			}
            // else{

            // }
        });
        this.bus.$off("List_"+this.DatosTablaDocumentos.EmitSeccionTabla);
        this.bus.$on("List_"+this.DatosTablaDocumentos.EmitSeccionTabla,(Tipo)=>{
            this.GetDocumentos(Tipo);
        });
        this.bus.$off("Delete_"+this.DatosTablaDocumentos.EmitSeccionTabla);
        this.bus.$on("Delete_"+this.DatosTablaDocumentos.EmitSeccionTabla,(Id)=>{
            this.BorrarDocumento(Id);
        });
        this.bus.$off("List_"+this.DatosTablaRefacciones.EmitSeccionTabla);
        this.bus.$on("List_"+this.DatosTablaRefacciones.EmitSeccionTabla,()=>{
            this.GetRefacciones();
        });
        this.bus.$off("Delete_"+this.DatosTablaRefacciones.EmitSeccionTabla);
        this.bus.$on("Delete_"+this.DatosTablaRefacciones.EmitSeccionTabla,(Id)=>{
            this.BorrarRefaccion(Id);
        });
        // if (this.Id != undefined) {
		// 	this.Activo.IdActivo = this.Id;
		// 	this.get_one();
		// }
    },
    computed:{
        // CodigoActivo:{
        //     get:function(){
        //         if (this.Activo.CodigoActivo!='') {
        //             let substr=this.Activo.Modelo.substring(0,(this.Activo.Modelo.length)-1);
        //             if (substr==this.Activo.CodigoActivo) {
        //                 return this.Activo.Modelo;
        //             }
        //             else{
        //                 return this.Activo.CodigoActivo;
        //             }
        //         }
        //         else{
        //             return this.Activo.Modelo;
        //         }
        //     },
        //     set:function(val){
        //         this.Activo.CodigoActivo=val;
        //     }
        // },
        typeCalendar:function(){
            return 'single';
        }
    }
}
</script>